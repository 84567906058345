import { EXMCaseStatusType } from '@/types/EXMCaseStatusType';
import { ICode } from '@/types/ICode';
import { ICustomerConfig } from '@/types/ICustomerConfig';
import { IParsedCase } from '@/types/IParsedCase';
import { isInsideElectron } from '@/utils/misc';
import { submit } from './xmedicus/callback';
import { parseRequest } from './xmedicus/request';

function convertMarkdownToHtml(text) {
  // Henrik's home-made markdown-to-html parser... God help us
  // Note, more complex parsers make better HTML, but XMedicus doesn't really support HTML.

  // Convert Markdown bold to HTML <b> tags
  text = text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
  // Convert Markdown italic to HTML <i> tags
  text = text.replace(/\*(.*?)\*/g, '<i>$1</i>');
  // Strip out all admonition tags
  text = text.replace(/:{3,}note\n/gm, '');
  text = text.replace(/\n:{3,}/gm, '');

  return text;
}

export async function submitToEHR(
  config: ICustomerConfig,
  externalId: string,
  note: string,
  patientId: string,
  doctorId: string,
  codes: ICode[],
  statusFlag: EXMCaseStatusType,
  externalNoteId?: string,
) {
  // is this still needed?
  const stringedNote = note.replaceAll('&#x200b;', '').replaceAll('&#x20;', ' ');

  let htmlNote = convertMarkdownToHtml(stringedNote);

  console.log('submitToEHR', { htmlNote });

  if (!isInsideElectron()) {
    return;
  }

  // Just call the XMedicus handling for now.
  return await submit(
    config,
    externalId,
    htmlNote,
    patientId,
    doctorId,
    codes,
    statusFlag,
    externalNoteId,
  );
}

export function parseRequestFromEHR(request: any): IParsedCase {
  // Just call the XMedicus handling for now
  return parseRequest(request);
}
