import levenshtein from 'fast-levenshtein';

const keywords = [
  'Henvisningsårsag',
  'Anamnese',
  'Allergier',
  'Dispositioner',
  'Tidligere',
  'Øvrige Lidelser',
  'Aktuelt medicin',
  'Kram',
  'Socialt',
  'Objektivt',
  'Konklusion',
  'Plan',
  'Aktuelle',
  'Tidligere Gyn',
  'Expositioner',
];

const addZeroWidthSpaces = (text: string) => {
  text = text.replaceAll(/^\n$/g, '&#x200b;\n&#x200b;');
  text = text.replaceAll(/\n$/g, '\n&#x200b;');
  text = text.replaceAll(/^\n/g, '&#x200b;\n');
  return text;
};

const prepareKeyword = (keyword: string) =>
  keyword.toLocaleLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');

// fuzzyFixToBoldKeywords performs fuzzy matching on the text to identify 'nyt afsnit' commands.
// It fuzzy matches with a distance of 1
// If a match is found, it replaces with the correct keyword and  bolds it
const fuzzyFixToBoldKeywords = (text: string) =>
  text
    .split('\n\n')
    .map((section: string, index: number) => {
      if (index === 0) return section;
      let found = false;

      for (let keyword of keywords) {
        const transcribedKeyword = section.slice(0, keyword.length);

        const distance = levenshtein.get(
          prepareKeyword(keyword),
          prepareKeyword(transcribedKeyword),
          { useCollator: true },
        );

        if (distance <= 1) {
          const regex = new RegExp(`^(${transcribedKeyword})[,. ]{0,2}`, 'i');
          if (regex.test(section)) {
            found = true;
            const subsequentText = section.slice(keyword.length).replace(/^[,. ]{0,2}/, '');
            return `\n\n**${keyword}**\n${subsequentText}`;
          }
        }
      }
      return found ? section : `\n\n${section}`;
    })
    .join('');

const formatText = (newSnippet: string) => {
  // Use consistent line breaks
  let formattedText = newSnippet.replaceAll('<br />', '\n');
  // Support 'nyt afsnit' commands
  formattedText = fuzzyFixToBoldKeywords(formattedText);
  // Prevent markdown new lines from being collapsed
  formattedText = addZeroWidthSpaces(formattedText);
  // Add tab before "Rp."
  formattedText = formattedText.replaceAll('Rp.', '\tRp.');
  return formattedText;
};

export default formatText;
