import useCases from '@/hooks/useCases';
import { autoFocusedFieldRef } from '@/utils/forms';
import { convertCaseUrgencyToXMStatusType } from '@/utils/misc';
import { DialogContentText, FormControlLabel, Switch, TextField } from '@mui/material';
import { transaction } from 'mobx';
import { observer } from 'mobx-react';
import { ChangeEvent, useState } from 'react';
import ConfirmDialog from './ConfirmDialog';
import DuplicateCodesWarning from './DuplicateCodesWarning';
import { EAudioProcessingStep } from '@/types/audio/EAudioProcessingStep';
import { FeedbackGetter } from './FeedbackGetter';
import { amber, red, yellow } from '@mui/material/colors';

interface IFlagDialogProps {
  open: boolean;
  onClose: () => void;
  hasDuplicateCodes?: boolean;
  confirmedNote: boolean;
}

export const FlagDialog = observer(
  ({ open, onClose, hasDuplicateCodes = false, confirmedNote = false }: IFlagDialogProps) => {
    const cases = useCases();
    const [flagReason, setFlagReason] = useState('');
    const [isUrgent, setIsUrgent] = useState(false);
    const [askForFeedback, _setAskForFeedback] = useState(Math.random() < 0.15);

    const flagAndClear = async () => {
      const status = convertCaseUrgencyToXMStatusType(isUrgent);

      await cases.flagOrCompleteAndSubmitCaseToEHR(
        status,
        isUrgent,
        true,
        flagReason,
        confirmedNote,
      );

      transaction(() => {
        cases.clearCase();
        cases.disableForceRedirectToNewEhr();
        cases.enableForceRedirectToInbox();
      });
    };

    const handleSubmit = async () => {
      if (!cases.isNew() || !cases.hasRecordingBeenUsed()) {
        await flagAndClear();

        handleClose();
        return;
      }

      await cases.waitForFullAudioAndProcessIt(async () => {
        await flagAndClear();

        handleClose();
      });
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setFlagReason(event.target.value);
    };

    const handleToggleUrgency = (event: ChangeEvent<HTMLInputElement>) => {
      setIsUrgent(event.target.checked);
    };

    const handleClose = () => {
      setFlagReason('');
      setIsUrgent(false);
      onClose();
    };

    const label = { inputProps: { 'aria-label': 'Toggle urgency' } };

    return (
      <ConfirmDialog
        title="Review"
        open={open}
        onCancel={handleClose}
        onAccept={handleSubmit}
        isLoading={
          cases.fullAudioProcessingStep === EAudioProcessingStep.STORING ||
          cases.segmentAudioProcessingStep === EAudioProcessingStep.STORING
        }
      >
        {confirmedNote ? (
          <DialogContentText sx={{ background: amber[100], p: 2 }}>
            Du er ved at godkende dit journalnotat uden gennemgang af en lægesekretær. Bemærk, at
            diktatet ikke vil blive lyttet til, og der vil ikke blive foretaget nogen rettelser.
            Lægesekretæren vil dog stadig læse notatet for kodning og opfølgende handlinger. Vær
            sikker på, at dit notat er korrekt og fuldt udfyldt.
          </DialogContentText>
        ) : (
          <DialogContentText>
            Du er ved at sende dit journalnotat til gennemsyn ved en lægesekretær. Fortæl
            lægesekretæren hvad der skal ske med dit notat på baggrund af patientens besøg (f.eks.
            kontrol, MR-scanning, afsluttes):
          </DialogContentText>
        )}
        <TextField
          inputRef={autoFocusedFieldRef}
          autoFocus
          value={flagReason}
          onChange={handleChange}
          margin="dense"
          id="name"
          label="Bemærkning"
          fullWidth
          variant="standard"
        />
        <FormControlLabel
          sx={{ my: 2, w: '100%' }}
          control={
            <Switch color="error" checked={isUrgent} onChange={handleToggleUrgency} {...label} />
          }
          label="Markér denne sag som hastende"
        />

        {askForFeedback && <FeedbackGetter caseId={cases.caseId} />}

        <DialogContentText>{hasDuplicateCodes && <DuplicateCodesWarning />}</DialogContentText>
      </ConfirmDialog>
    );
  },
);
