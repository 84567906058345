import { CODE_CATEGORY_DIAGNOSIS } from '@/config/constants';
import useCases from '@/hooks/useCases';
import useUser from '@/hooks/useUser';
import { ICode } from '@/types/ICode';
import { hasDuplicateCodesShallow } from '@/utils/codes';
import { Box, Button } from '@mui/material';
import { transaction } from 'mobx';
import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import { FlagDialog } from '../dialogs/FlagDialog';
import { NoDiagnosisWarning } from '../dialogs/NoDiagnosisWarning';
import { SubmitDialog } from '../dialogs/SubmitDialog';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import { isAlphaTester, isLLMDoctor } from '@/services/featureFlag';

export const BottomMenu = observer(() => {
  const navigate = useNavigate();
  const cases = useCases();
  const [submitOpen, setSubmitOpen] = useState(false);
  const [flagOpen, setFlagOpen] = useState(false);
  const [confirmedNote, setConfirmedNote] = useState(false);
  const [noDiagnosisWarningOpen, setNoDiagnosisWarningOpen] = useState<boolean>(false);
  const user = useUser();
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);

  const hasDiagnosis = () => {
    return cases.selectedCodes.some((code: ICode) => code.type === CODE_CATEGORY_DIAGNOSIS);
  };

  const handleSubmitOpen = () => {
    if (!hasDiagnosis()) {
      setNoDiagnosisWarningOpen(true);
      return;
    }

    setSubmitOpen(true);
  };
  const handleSubmitClose = () => {
    setSubmitOpen(false);
  };

  const handleFlagOpen = (confirmedNoteState: boolean) => {
    if (!hasDiagnosis()) {
      setNoDiagnosisWarningOpen(true);
      return;
    }
    setConfirmedNote(confirmedNoteState);
    setFlagOpen(true);
  };
  const handleFlagClose = () => {
    setFlagOpen(false);
  };

  const handleNoDiagnosisWarningAccept = () => {
    // Close No Diagnosis Warning dialog.
    setNoDiagnosisWarningOpen(false);
  };

  const handleSaveChangesClick = () => {
    // Unlock the case first if the case is locked by current user,
    // then save the changes and submit the case to EHR.
    const unlock = cases.isFlagged() && cases.isLockedByMe();
    cases.saveChangesAndSubmitCaseToEHR(unlock).then(() => {
      transaction(() => {
        cases.clearCase();
        cases.disableForceRedirectToNewEhr();
        cases.enableForceRedirectToInbox();
      });
    });
  };

  const submitAndClear = async () => {
    // Unlock the case first if the case is locked by current user,
    // then complete the case and submit it to EHR..
    const unlock = cases.isFlagged() && cases.isLockedByMe();
    cases.completeAndSubmitCaseToEHR(user.initials, unlock).then(() => {
      handleSubmitClose();
      transaction(() => {
        cases.clearCase();
        cases.disableForceRedirectToNewEhr();
        cases.enableForceRedirectToInbox();
      });
    });
  };

  const handleSubmitClick = async () => {
    if (!cases.isNew() || !cases.hasRecordingBeenUsed()) {
      await submitAndClear();
      return;
    }

    await cases.waitForFullAudioAndProcessIt(async () => {
      await submitAndClear();
    });
  };

  const hasDuplicateCodesMemoised = useMemo(
    () => hasDuplicateCodesShallow(cases.selectedCodes),
    [cases.selectedCodes],
  );

  return (
    <Box
      sx={{
        py: 2,
        px: 2,
        mt: 'auto',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', gap: 1 }}>
        {user.isSecretary() && !cases.isDraft() && (
          <Box sx={{ display: 'flex' }}>
            <Button
              variant="contained"
              size="large"
              color="success"
              onClick={handleSubmitOpen}
              disabled={!cases.loaded || cases.isCompleted() || cases.isNoteEmpty()}
              sx={{ borderRadius: 1 }}
              className="dd-privacy-allow"
            >
              Send notat til XMedicus
            </Button>
          </Box>
        )}
        {cases.isFlagged() && (
          <Button
            variant="outlined"
            size="large"
            color="secondary"
            disabled={!cases.loaded || cases.isCompleted() || cases.isNoteEmpty()}
            onClick={handleSaveChangesClick}
            sx={{ borderRadius: 1 }}
            className="dd-privacy-allow"
          >
            Gem ændringer
          </Button>
        )}
        {!cases.isFlagged() && !cases.isDraft() && (
          <Button
            variant="contained"
            size="large"
            color="success"
            disabled={cases.isNoteEmpty()}
            onClick={() => handleFlagOpen(false)}
            sx={{ borderRadius: 1 }}
            className="dd-privacy-allow"
          >
            Afslut notat
          </Button>
        )}
        {!cases.isFlagged() && !cases.isDraft() && isLLMDoctor() && (
          <Button
            variant="contained"
            size="large"
            color="success"
            disabled={cases.isNoteEmpty()}
            onClick={() => handleFlagOpen(true)}
            sx={{ borderRadius: 1 }}
            className="dd-privacy-allow"
          >
            Godkend og afslut notat
          </Button>
        )}
        {cases.isDraft() && (
          <Button
            variant="contained"
            size="large"
            color="success"
            disabled={cases.isNoteEmpty()}
            onClick={() => handleFlagOpen(false)}
            sx={{ borderRadius: 1 }}
            className="dd-privacy-allow"
          >
            Recover Note
          </Button>
        )}

        {isAlphaTester() && cases.isDraft() && (
          <Button
            variant="outlined"
            size="large"
            color="warning"
            disabled={!cases.loaded || cases.isCompleted()}
            onClick={() => setIsArchiveDialogOpen(true)}
            sx={{ borderRadius: 1 }}
            className="dd-privacy-allow"
          >
            Archive Case
          </Button>
        )}
      </Box>
      <SubmitDialog
        open={submitOpen}
        handleClose={handleSubmitClose}
        handleSubmit={handleSubmitClick}
        hasDuplicateCodes={hasDuplicateCodesMemoised}
      />
      <FlagDialog
        open={flagOpen}
        confirmedNote={confirmedNote}
        onClose={handleFlagClose}
        hasDuplicateCodes={hasDuplicateCodesMemoised}
      />
      <NoDiagnosisWarning open={noDiagnosisWarningOpen} onAccept={handleNoDiagnosisWarningAccept} />
      <ConfirmDialog
        title="Archive Case"
        open={isArchiveDialogOpen}
        onAccept={() => cases.deleteCase().then(() => navigate('/'))}
        onCancel={() => setIsArchiveDialogOpen(false)}
      >
        Er du sikker på, at du vil arkivere denne sag?
      </ConfirmDialog>
    </Box>
  );
});
