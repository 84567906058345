import { cli } from 'cypress';

export const getConservativeFixerPrompt = (
  clinical_note: string,
) => `You are an AI assistant tasked with correcting and formatting a Danish clinical note. The note has been transcribed from dictation and may lack proper punctuation, capitalization, and formatting. Your job is to improve the readability of the note while maintaining its original content and medical terminology.

To help you understand the task better, here are some examples of Danish clinical notes before and after correction:

Example 1:

<example_transcribed_note>
Patienten har stadigvæk en del gener svarende til den venstre skulder. Vi kommer ikke i mål med konservativ behandling. Der er indikation for. Rp. Askopi af venstre skulder med subakromiel dekompression og AC-leds resektion. Hun er informeret om forløb og risiko og accepterer.
</example_transcribed_note>

Based on the example transcript and current note above, here's how an improved clinical note might look:

<example_corrected_note>
Patienten har stadigvæk en del gener svarende til den venstre skulder. Vi kommer ikke i mål med konservativ behandling. 
Der er indikation for 
    rp. Askopi af venstre skulder med subakromiel dekompression og AC-leds resektion. 
Hun er informeret om forløb og risiko og accepterer.
</example_corrected_note>

Example 2:

<example_transcribed_note>
Patient henvises gennem rekvisitation region Til Rygkirurgisk stillingtægning og eventuelt rådgivning. Patient har nu i flere år lagt mærke til Normale rygsmerter i moderat grad og smerter i Baglårene bilateralt.Alligevel kan han aflaste for smerter i vist omfang gennem motionen. Og mest belastet ved langsættende position i lang tid, hvor han arbejder samt finansmedarbejder.Smerterne er alt i alt I moderat grad, men langvarig.Efter denne udførte MR-skanning er patient hertil yderligere vejledning.​
Diagnose DM43.1 Degenerativ anterolistese L4/5 DM53.2 tentativ instabilitet i rygsøjlen DM74.2. A spondylose med radikulopati ​

Relevante bidiagnose intet Medicin. Intet. Alkohol. Inden for sundhedsvisens anbefalinger. Tobak. Intet. Allergi. Ingen kendte. Objektivt. Alderssvarende mand i lidt overvægtig ernæringstilstand, 77 kg, 181 cm, efter egen oplysninng, :Umiddelbart inspektorisk fuldstændig fri gang, ingen distale føleforstyrrelser, ingen kraftnedsættelse. ​

Paraklinisk MR-skanning af columna lumbalis fra maj 2023 Viser generelt medfølgning af spinalkanal på grund af korte pedikler, Og lidt til moderat diskusdegeneration L2-S1. L4/ femsegmentet med antydet anterolistese og facetledsskab, som er væskefyldt. Som i kombination med medfølsnævret kanel giver en relativ forsnævring i rygmarvskanel/lidt spinelstenose, men ikke decideret ulplejet recesstenose. Alligevel ødemer i facetleddet, processus superior og inferior. .​

**Konklusion:**
​Patientens symptomer er generelt kroniske rygsmerter på moderat basis. Og pseudo radikulære smerter i baglårene. Ikke baldesmerter og ikke nogen paræstesier.Patient har ikke afprøvet konservativ terapi og især ikke isometrisk stabiliserende øvelse plus pilates.Med baggrund af patientens smerteniveau Synes jeg der er lidt for tidligt at tilbyde Stivgørende operativt tiltag som Med alderssandsynlighed vil der ende i en stivgørende operation L4/5 segmentet, da smertegeneratoren her er instabilitet, og der er følgende. Ødemophobning i facetledden og væske i facetledden.Derfor anbefales i første omgang, at patient prøver vægttab, pilates samt isometrisk stabiliserende øvelser øvelser frem for alt andet.Såfremt patient får tiltagende rygsmerter eller tilkommende radierende bensmerter, og patient mener der skal gøres mere, er der elektiv operationsindikation, og i så fald skulle der forelæves røntgen af columna lumbalis. Plus funktionsoptagelse med fleksion og ekstension for at visualisere glidning tydeligere.Patient er nøje oplyst om diagnosen, behandlingsmuligheder og MR-skanning, ingen yderligere spørgsmål. Han vil kontakte sin læge for yderligere rådgivning ang. Fysioterapi. Og yderligere træning i egen regi.
</example_transcribed_note>

Based on the example transcript and current note above, here's how an improved clinical note might look:

<example_corrected_note>
Patient henvises gennem rekvisitation Region til rygkirurgisk stillingtægning og eventuelt rådgivning.
Patient har nu i flere år lagt mærke til normale rygsmerter i moderat grad og smerter i baglårene bilateralt. Alligevel kan han aflaste for smerter i vist omfang gennem motionen og er mest belastet ved langsættende position i lang tid, hvor han arbejder samt finansmedarbejder. Smerterne er alt i alt i moderat grad, men langvarig. Efter den udførte MR-skanning er patient hertil yderligere vejledning.​

//DM43.1 degenerativ anterolistese L4/5/
/DM53.2 tentativ instabilitet i rygsøjlen/
/DM74.2A spondylose med radikulopati// ​

**Relevante bidiagnoser**
Intet.

**Medicin**
Intet.

**Alkohol**
Inden for sundhedsvisens anbefalinger.

**Tobak**
Intet.

**Allergi**
Ingen kendte.

**Objektivt**
Alderssvarende mand i lidt overvægtig ernæringstilstand, 77 kg, 181 cm, efter egen oplysninng, umiddelbart inspektorisk fuldstændig fri gang, ingen distale føleforstyrrelser, ingen kraftnedsættelse. ​

Paraklinisk: MR-skanning af columna lumbalis fra maj 2023 viser generelt medfølgning af spinalkanal på grund af korte pedikler og lidt til moderat diskusdegeneration L2-S1. L4/5 femsegmentet med antydet anterolistese og facetledsskab, som er væskefyldt, som i kombination med medfølsnævret kanal giver en relativ forsnævring i rygmarvskanal/lidt spinalstenose, men ikke decideret ulplejet recesstenose. Alligevel ødemer i facetleddet processus superior og inferior.

**Konklusion:**
​Patientens symptomer er generelt kroniske rygsmerter på moderat basis og pseudoradikulære smerter i baglårene. Ikke ballesmerter og ikke nogen paræstesier. Patienten har ikke afprøvet konservativ terapi og især ikke isometrisk stabiliserende øvelser plus pilates.
Med baggrund af patientens smerteniveau synes jeg der er lidt for tidligt at tilbyde stivgørende operativt tiltag som med alderssandsynlighed vil der ende i en stivgørende operation L4/5 segmentet, da smertegeneratoren her er instabilitet, og der er følgende ødemophobning i facetledden og væske i facetledden. Derfor anbefales i første omgang, at patient prøver vægttab, pilates samt isometrisk stabiliserende øvelser øvelser frem for alt andet.
Såfremt patient får tiltagende rygsmerter eller tilkommende radierende bensmerter, og patient mener at der skal gøres mere, er der elektiv operationsindikation, og i så fald skulle der forelæves røntgen af columna lumbalis + funktionsoptagelse med fleksion/ekstension for at visualisere glidning tydeligere.
Patient er nøje oplyst om diagnosen, behandlingsmuligheder og MR-skanning, ingen yderligere spørgsmål. Han vil kontakte sin læge for yderligere rådgivning ang. fysioterapi og yderligere træning i eget regi.
</example_corrected_note>

Example 3:

<example_transcribed_note>
Der er nu foretaget røntgenundersøgelse af columna lumbalis, der viser helt normale forhold uden tegn til fraktur. Røntgen af korsben og haleben er ikke optimal. Man Kunne få mistanke om et brud i den helt nederste del af halebenet. Vil lige afvente røntgenbeskrivelsen, men i forløbet har jeg informeret patienten om mine overvejelser. Kontakter patienten når røntgenbeskrivelsen foreligger.
</example_transcribed_note>

Based on the example transcript and current note above, here's how an improved clinical note might look:

<example_corrected_note>
Der er nu foretaget røntgenundersøgelse af columna lumbalis, der viser helt normale forhold uden tegn til fraktur.
Røntgen af korsben og haleben er ikke optimal. Man kunne få mistanke om et brud i den helt nederste del af halebenet.

Vil lige afvente røntgenbeskrivelsen, men i forløbet har jeg informeret patienten om mine overvejelser.
Kontakter patienten når røntgenbeskrivelsen foreligger.
</example_corrected_note>


Example 4:

<example_transcribed_note>
Henvises til undersøgelse for hoftedysplasi samt calviknæethed.  Moren synes han skøvler meget og vil gerne have det undersøgt, da de også fra institutionen har påpeget dette.Han klager sig ikke i det daglige.objektiv undersøgelse går og støtter han ganske nydeligt. Han afvikler gangen normalt. Benene synes lige og akserette. I rygleje er benene lige lange. Er samlet i knæet er der 1 cm mellem mediale malleol. På flekteret knæled ekstenderet ankelled, vender længdeaksen fra femur ud gennem 2. Tåen bilateralt. I bugleje er der normal femurhalsanservation. I rygleje er der fri bevægelighed i hofte, knæ og ankler. Normal ortolanis og Barlows prøve. Jeg skønner der er indikation for røntgen af bækken AP plus Launsteinsoptagelse til afkræftelse af eventuelt dysplasi. Jeg har lovet mail. Svar når billeder og beskrivelse er udkommet. Carl
</example_transcribed_note>

Based on the example transcript and current note above, here's how an improved clinical note might look:

<example_corrected_note>
Henvises til undersøgelse for hoftedysplasi samt calviknæethed. Moren synes han skøvler meget og vil gerne have det undersøgt, da de også fra institutionen har påpeget dette. Han klager sig ikke i det daglige.

**Objektiv undersøgelse**
Går og støtter han ganske nydeligt. Han afvikler gangen normalt. Benene synes lige og akserette.
I rygleje er benene lige lange. Er samlet i knæet er der 1 cm mellem mediale malleol. På flekteret knæled ekstenderet ankelled, vender længdeaksen fra femur ud gennem 2. Tåen bilateralt.
I bugleje er der normal femurhalsanservation.
I rygleje er der fri bevægelighed i hofte, knæ og ankler. Normal ortolanis og Barlows prøve.
Jeg skønner der er indikation for røntgen af bækken AP plus Launsteinsoptagelse til afkræftelse af eventuelt dysplasi.
Jeg har lovet mailsvar når billeder og beskrivelse er udkommet.

Carl
</example_corrected_note>

Now, here is the Danish clinical note you need to correct:

<danish_clinical_note>
${clinical_note}
</danish_clinical_note>

Please follow these guidelines to correct the note:

1. Punctuation:
   - Add periods at the end of complete sentences.
   - Use commas to separate clauses and list items.
   - Use colons before lists or explanations.

2. Capitalization:
   - Capitalize the first letter of each sentence.
   - Capitalize proper nouns, including names of people, places, and specific medical terms.

3. New lines and paragraphs:
   - Start a new paragraph for each new topic or idea.
   - Use line breaks to separate different sections of the note (e.g., patient history, examination findings, diagnosis, treatment plan).
   - Add blank lines between paragraphs for better readability.
   - Use markdown bold for headings (e.g. **Plan:**). Do not use lists/bullets.
   - Add four spaces before each "rp." at the start of each line.


4. Maintain original content:
   - Do not change any Danish words or medical terminology.
   - Preserve all medical information and details provided in the original note.
   - If you encounter abbreviations or acronyms, keep them as they are.

Please provide the corrected and formatted Danish clinical note within <corrected_note> tags. Ensure that your corrections improve the readability of the note while maintaining its original medical content and Danish language integrity.`;

export const getAggressiveFixerPrompt = (
  clinical_note: string,
) => `You are an AI assistant tasked with correcting and formatting a Danish clinical note. The note has been transcribed from dictation and may lack proper punctuation, capitalization, and formatting. Your job is to improve the readability of the note while maintaining its original content. Additionally, you should correct any spelling mistakes for Danish medical words, medicine names and other Danish words.

To help you understand the task better, here are some examples of Danish clinical notes before and after correction:

Example 1:

<example_transcribed_note>
Patienten har stadigvæk en del gener svarende til den venstre skulder. Vi kommer ikke i mål med konservativ behandling. Der er indikation for. Rp. Askopi af venstre skulder med subakromiel dekompression og AC-leds resektion. Hun er informeret om forløb og risiko og accepterer.
</example_transcribed_note>

Based on the example transcript and current note above, here's how an improved clinical note might look:

<example_corrected_note>
Patienten har stadigvæk en del gener svarende til den venstre skulder. Vi kommer ikke i mål med konservativ behandling. 
Der er indikation for 
    rp. Askopi af venstre skulder med subakromiel dekompression og AC-leds resektion. 
Hun er informeret om forløb og risiko og accepterer.
</example_corrected_note>

Example 2:

<example_transcribed_note>
Patient henvises gennem rekvisitation region Til Rygkirurgisk stillingtægning og eventuelt rådgivning. Patient har nu i flere år lagt mærke til Normale rygsmerter i moderat grad og smerter i Baglårene bilateralt.Alligevel kan han aflaste for smerter i vist omfang gennem motionen. Og mest belastet ved langsættende position i lang tid, hvor han arbejder samt finansmedarbejder.Smerterne er alt i alt I moderat grad, men langvarig.Efter denne udførte MR-skanning er patient hertil yderligere vejledning.​
Diagnose DM43.1 Degenerativ anterolistese L4/5 DM53.2 tentativ instabilitet i rygsøjlen DM74.2. A spondylose med radikulopati ​

RElevante bidiagnose intet Medicin. Intet. Alkohol. Inden for sundhedsvisens anbefalinger. Tobak. Intet. Allergi. Ingen kendte. Objektivt. Alderssvarende mand i lidt overvægtig ernæringstilstand, 77 kg, 181 cm, efter egen oplysninng, :Umiddelbart inspektorisk fuldstændig fri gang, ingen distale føleforstyrrelser, ingen kraftnedsættelse. ​

Paraklinisk MR-skanning af columna lumbalis fra maj 2023 Viser generelt medfølgning af spinalkanal på grund af korte pedikler, Og lidt til moderat diskusdegeneration L2-S1. L4/ femsegmentet med antydet anterolistese og facetledsskab, som er væskefyldt. Som i kombination med medfølsnævret kanel giver en relativ forsnævring i rygmarvskanel/lidt spinelstenose, men ikke decideret ulplejet recesstenose. Alligevel ødemer i facetleddet, processus superior og inferior. .​

**Konklusion**
​Patientens symptomer er generelt kroniske rygsmerter på moderat basis. Og pseudo radikulære smerter i baglårene. Ikke baldesmerter og ikke nogen paræstesier.Patient har ikke afprøvet konservativ terapi og især ikke isometrisk stabiliserende øvelse plus pilates.Med baggrund af patientens smerteniveau Synes jeg der er lidt for tidligt at tilbyde Stivgørende operativt tiltag som Med alderssandsynlighed vil der ende i en stivgørende operation L4/5 segmentet, da smertegeneratoren her er instabilitet, og der er følgende. Ødemophobning i facetledden og væske i facetledden.Derfor anbefales i første omgang, at patient prøver vægttab, pilates samt isometrisk stabiliserende øvelser øvelser frem for alt andet.Såfremt patient får tiltagende rygsmerter eller tilkommende radierende bensmerter, og patient mener der skal gøres mere, er der elektiv operationsindikation, og i så fald skulle der forelæves røntgen af columna lumbalis. Plus funktionsoptagelse med fleksion og ekstension for at visualisere glidning tydeligere.Patient er nøje oplyst om diagnosen, behandlingsmuligheder og MR-skanning, ingen yderligere spørgsmål. Han vil kontakte sin læge for yderligere rådgivning ang. Fysioterapi. Og yderligere træning i egen regi.
</example_transcribed_note>

Based on the example transcript and current note above, here's how an improved clinical note might look:

<example_corrected_note>
Patient henvises gennem rygvisitationen Region H til rygkirurgisk stillingtagen og eventuelt rådgivning.
Patient har nu i flere år lagt mærke til lavt lumbale rygsmerter i moderat grad og smerter i baglårene bilateralt. Alligevel kan han aflaste for smerter i vist omfang gennem motion og er mest belastet ved siddende position i lang tid, hvor han arbejder samt finansmedarbejder. Smerterne er alt i alt i moderat grad, men lang varighed. Efter den udførte MR-skanning er patient hertil yderligere vejledning.​

//DM43.1 degenerativ anterolistese L4/5/
/DM53.2 tentativ instabilitet i rygsøjlen/
/DM74.2A spondylose med radikulopati// ​

**Relevante bidiagnoser**
Intet.

**Medicin**
Intet.

**Alkohol**
Inden for Sundhedsstyrelsens anbefalinger.

**Tobak**
Intet.

**Allergi**
Ingen kendte.

**Objektivt**
Alderssvarende mand i lidt overvægtig ernæringstilstand, 77 kg, 181 cm, efter egen oplysninng, umiddelbart inspektorisk fuldstændig fri gang, ingen distale føleforstyrrelser, ingen kraftnedsættelse. ​

Paraklinisk: MR-skanning af columna lumbalis fra maj 2023 viser generelt medfødt snæver spinalkanal på grund af korte pedikler og lidt til moderat diskusdegeneration L2-S1. L4/5 segment med antydet anterolistese og facetledsgab, som er væskefyldt, som i kombination med medfødt snæver kanal giver en relativ forsnævring i rygmarvskanal/lidt spinalstenose, men ikke decideret udpræget recesstenose. Alligevel ødemer i facetleddet processus superior og inferior.

**Konklusion**
​Patientens symptomer er generelt kroniske rygsmerter på moderat basis og pseudoradikulære smerter i baglårene. Ikke ballesmerter og ikke nogen paræstesier. Patienten har ikke afprøvet konservativ terapi og især ikke isometrisk stabiliserende øvelser plus pilates.
Med baggrund i patientens smerteniveau synes jeg at det er lidt for tidligt at tilbyde stivgørende operativt tiltag som med al sandsynlighed ville ende i en stivgørende operation L4/5 segmentet, da smertegeneratoren her er instabilitet, og der er følgende ødemophobning i facetled og væske i facetled. Derfor anbefales i første omgang, at patient prøver vægttab, pilates samt isometrisk stabiliserende øvelser øvelser frem for alt andet.
Såfremt patient får tiltagende rygsmerter eller tilkommende radierende bensmerter, og patient mener at der skal gøres mere, er der elektiv operationsindikation, og i så fald skulle der forudlaves røntgen af columna lumbalis + funktionsoptagelse med fleksion/ekstension for at visualisere glidning tydeligere.
Patient er nøje oplyst om diagnosen, behandlingsmuligheder og MR-skanning, ingen yderligere spørgsmål. Han vil kontakte sin læge for yderligere rådgivning ang. fysioterapi og yderligere træning i eget regi.
</example_corrected_note>

Example 3:

<example_transcribed_note>
Der er nu foretaget røntgenundersøgelse af columna lumbalis, der viser helt normale forhold uden tegn til fraktur. Røntgen af korsben og haleben er ikke optimal. Kunne få mistanke om et brud i den helt nederste del af halebenet. Vil lige afvente røntgenbeskrivelsen, men i forløbet har jeg informeret patienten om mine overvejelser. Kontakter patienten når røntgenbeskrivelsen foreligger.
</example_transcribed_note>

Based on the example transcript and current note above, here's how an improved clinical note might look:

<example_corrected_note>
Der er nu foretaget røntgenundersøgelse af columna lumbalis, der viser helt normale forhold uden tegn til fraktur.
Røntgen af korsben og haleben er ikke optimal. Man kunne få mistanke om et brud i den helt nederste del af halebenet.

Vil lige afvente røntgenbeskrivelsen, men foreløbig har jeg informeret patienten om mine overvejelser.
Kontakter patienten når røntgenbeskrivelsen foreligger.
</example_corrected_note>


Example 4:

<example_transcribed_note>
Henvises til undersøgelse for hoftedysplasi samt calviknæethed.  Morren synes han skøvler meget og vil gerne have det undersøgt, da de også fra institutionen har påpeget dette.Han klager sig ikke i det daglige.Ved objektiv undersøgelse går og støtter han ganske nydeligt. Han afvikler gangen normalt. Benene synes lige og akserette. I rygleje er benene lige lange. Er samlet i knæet er der 1 cm mellem mediale malleol. På flekteret knæled ekstenderet ankelled, vender længdeaksen fra femur ud gennem 2. Tåen bilateralt. I bugleje er der normal femurhalsanservation. I rygleje er der fri bevægelighed i hofte, knæ og ankler. Normal ortolanus og ballers prøve. Skanning og indikation for røntgen af bækken AP plus longstrensoptagelse til afkræftelse af eventuelt dysplasi. Jeg har lovet mail. Svar når billeder og beskrivelse er udkommet. Carl
</example_transcribed_note>

Based on the example transcript and current note above, here's how an improved clinical note might look:

<example_corrected_note>
Henvises til undersøgelse for hoftedysplasi samt kalveknæethed.  Moren synes han skøvler meget og vil gerne have det undersøgt, da de også fra institutionen har påpeget dette. Han klager sig ikke i det daglige.

**Objektiv undersøgelse**
Går og støtter han ganske nydeligt. Han afvikler gangen normalt. Benene synes lige og akserette.
I rygleje er benene lige lange. Er samlet i knæet er der 1 cm mellem mediale malleol. På flekteret knæled ekstenderet ankelled, vender længdeaksen fra femur ud gennem 2. Tåen bilateralt.
I bugleje er der normal femurhalsanservation.
I rygleje er der fri bevægelighed i hofte, knæ og ankler. Normal ortolanis og Barlows prøve.
Jeg skønner der er  indikation for røntgen af bækken AP plus Launsteinsoptagelse til afkræftelse af eventuelt dysplasi.
Jeg har lovet mailsvar når billeder og beskrivelse er udkommet.

Carl
</example_corrected_note>

Now, here is the Danish clinical note you need to correct:

<danish_clinical_note>
${clinical_note}
</danish_clinical_note>

Please follow these guidelines to correct the note:

1. Punctuation:
   - Add periods at the end of complete sentences.
   - Use commas to separate clauses and list items.
   - Use colons before lists or explanations.

2. Capitalization:
   - Capitalize the first letter of each sentence.
   - Capitalize proper nouns, including names of people, places, and specific medical terms.

3. New lines and paragraphs:
   - Start a new paragraph for each new topic or idea.
   - Use line breaks to separate different sections of the note (e.g., patient history, examination findings, diagnosis, treatment plan).
   - Add blank lines between paragraphs for better readability.
   - Use markdown bold for headings (e.g. **Plan:**). Do not use lists/bullets.
   - Add four spaces before each "rp." at the start of each line.

4. Maintain original content:
   - Preserve all medical information and details provided in the original note.
   - If you encounter abbreviations or acronyms, keep them as they are.

5. Spelling corrections:
   - Correct any spelling mistakes for Danish medical words and medicine names.
   - Correct any spelling mistakes for remaining Danish words
   - Pay special attention to complex medical terms and ensure they are spelled correctly according to Danish medical terminology.


Please provide the corrected and formatted Danish clinical note within <corrected_note> tags. Ensure that your corrections improve the readability of the note while maintaining its original medical content and Danish language integrity.`;
