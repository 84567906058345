import { CaseDataBar } from '@/components/case/CaseDataBar';
import { CaseInput } from '@/components/case/CaseInput';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog';
import ConfirmUnsavedChangesDialog, {
  useConfirmUnsavedChanges,
} from '@/components/dialogs/ConfirmUnsavedChangesDialog';
import MainLayout from '@/components/layout/MainLayout';
import { PATH_NEW_CASE } from '@/config/paths';
import useCases from '@/hooks/useCases';
import useUser from '@/hooks/useUser';
import useUserPreferences from '@/hooks/useUserPreferences';
import useWindowSize from '@/hooks/useWindowSize';
import { formatCaseLocked, isInsideElectron } from '@/utils/misc';
import { flexColumnFullHeight } from '@/utils/theme';
import { Box, CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CodeSelection } from '../components/case/CodeSelection';
import { getPanelElement, Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

export const CasePage = observer(() => {
  const cases = useCases();
  const location = useLocation();
  const { blocker } = useConfirmUnsavedChanges(
    !cases.forceRedirectToInbox &&
      !cases.forceRedirectToNewEhrPage &&
      (!cases.isLocked() || cases.isLockedByMe()) &&
      cases.isDirty(),
  );
  const codesPanelRef = useRef(null);
  const size = useWindowSize();
  const navigate = useNavigate();
  const user = useUser();
  const userPreferences = useUserPreferences();
  const [isLoading, setIsLoading] = useState(true);

  const handleLockDialogCancel = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    navigate(userPreferences.lastInboxPath);
    cases.clearCase();
    // @todo: Switch to the code below after testing with multiple roles.
    // transaction(() => {
    //   cases.clearCase();
    //   cases.disableForceRedirectToNewEhr();
    //   cases.enableForceRedirectToInbox();
    // });
  };

  const handleLockDialogAccept = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    // Re-lock the case to current user.
    cases.lockCase();
  };

  const handleLeavePage = (next: () => void) => {
    // If user tries to leave the page and the case is locked to them,
    // unlock the case and clear it in the store.
    if (cases.isFlagged() && cases.isLockedByMe()) {
      cases.unlockCase().then(() => {
        cases.clearCase();

        next();
      });
      return;
    }

    // If the case is not locked to the current user,
    // stop recording and clear the case in the store.
    cases.clearCase();

    next();
  };

  useEffect(() => {
    // We use CasePage for a few pages, such as /new, /newehr, /edit and /readonly.
    if (location.pathname !== PATH_NEW_CASE) {
      setIsLoading(false);
      return;
    }

    // The Completed status is final, so Completed cases are read-only.
    // After opening a Completed case and then going to the frontpage (case view),
    // we don't need to ask user whether user wants to lose the changes, so we don't clear the case data on modal action,
    // which means we need to clear the case data here instead.
    if (cases.isCompleted()) {
      cases.clearCase();
    }

    // Create a new case on opening the New Case page through the Web version.
    if (!isInsideElectron()) {
      cases.clearCase();
      cases
        .createNewCase()
        .then(() => setIsLoading(false))
        .catch(() => cases.enableForceRedirectToInbox());
    }
  }, [location.key]);

  useEffect(() => {
    // If it's a flagged case and it's not yet locked by anyone,
    // lock the case to current user.
    if (user.loaded && cases.loaded && cases.isFlagged() && !cases.isLocked()) {
      cases.lockCase();
    }
    if (!cases.isLocked() && cases.locked) {
      cases.unlockCase();
    }
  }, [user.loaded, cases.loaded]);

  const windowHeightWithoutHeader = size.height - 80;

  useEffect(() => {
    if (cases.codesEditMode && codesPanelRef.current) {
      codesPanelRef.current.resize(50);
    }
  }, [cases.codesEditMode]);

  return (
    <MainLayout topBarContent={<CaseDataBar />}>
      <Box
        sx={{
          ...flexColumnFullHeight,
          flexDirection: 'row',
          height: 'auto',
          maxHeight: windowHeightWithoutHeader,
        }}
      >
        {isLoading || !cases.loaded ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <CircularProgress color="inherit" size={50} />
          </Box>
        ) : (
          <PanelGroup autoSaveId="example" direction="horizontal" style={{ margin: '0 16px' }}>
            <Panel minSize={45}>
              <CaseInput />
            </Panel>
            {cases.codesEditMode && (
              <>
                <PanelResizeHandle
                  style={{
                    width: 8,
                    borderRadius: 8,
                    backgroundColor: '#eaeaea',
                    margin: '24px 0',
                  }}
                />
                <Panel
                  defaultSize={cases.codesEditMode ? 50 : 25}
                  minSize={25}
                  ref={codesPanelRef}
                  style={{
                    padding: 8,
                  }}
                >
                  <CodeSelection editMode={cases.codesEditMode} />
                </Panel>
              </>
            )}
          </PanelGroup>
        )}
      </Box>
      <ConfirmUnsavedChangesDialog
        blocker={blocker}
        message="Er du sikker på, at du vil forlade siden? Ændringer, du har foretaget, gemmes muligvis ikke."
        onAccept={handleLeavePage}
      />
      <ConfirmDialog
        title="En anden sag er aktiv"
        open={cases.currentCaseNotSavedWarning}
        acceptActionLabel="Forstået"
        onAccept={() => cases.disableCurrentCaseNotSavedWarning()}
      >
        En anden sag er i øjeblikket aktiv og er endnu ikke afsluttet. Indsend eller marker venligst
        sagen, før du starter en ny.
      </ConfirmDialog>
      {cases.isFlagged() && (
        <ConfirmDialog
          title="Sagen er låst"
          description=""
          cancelActionLabel="Annuller"
          acceptActionLabel="Overtag"
          open={cases.isLocked() && !cases.isLockedByMe()}
          onCancel={handleLockDialogCancel}
          onAccept={handleLockDialogAccept}
        >
          {formatCaseLocked(cases.locked)}
        </ConfirmDialog>
      )}
    </MainLayout>
  );
});
