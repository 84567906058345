import { useState, useCallback, useRef, useEffect } from 'react';
import RecordRTC from 'recordrtc';
import fixWebmDuration from 'webm-duration-fix';

import {
  trackPauseResumeFullRecording,
  trackStartRecording,
  trackStopFullRecording,
} from '@/services/analytics';
import createVAD from '@/utils/audio/vad';
import { MicVAD } from '@ricky0123/vad-web';

const TIMER_UPDATE_INTERVAL = 1000;

type UseAudioRecorderReturn = {
  startRecording: (device: MediaDeviceInfo) => void;
  stopRecording: () => void;
  resetRecorder: () => void;
  toggleRecording: (device: MediaDeviceInfo) => void;
  audioBlob: Blob | null;
  recordingTime: number;
  isRecording: boolean;
  isPaused: boolean;
  isSpeaking: boolean;
  isInitializing: boolean;
  // vad: MicVAD | null;
};

const useAudioRecorder = (onSpeechEnd: (blob: Blob) => Promise<void>): UseAudioRecorderReturn => {
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [recordingTime, setRecordingTime] = useState<number>(0);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(true);
  const [isSpeaking, setIsSpeaking] = useState<boolean>(false);
  const [isInitializing, setIsInitializing] = useState<boolean>(false);
  const recorder = useRef<RecordRTC | null>(null);
  const MediaStream = useRef<MediaStream | null>(null);
  const vad = useRef<MicVAD | null>(null);
  const intervalRef = useRef<number | null>(null);

  // Manages the timer
  const manageIntervals = useCallback((start: boolean) => {
    if (start) {
      intervalRef.current = window.setInterval(() => {
        setRecordingTime((prev) => prev + TIMER_UPDATE_INTERVAL);
      }, TIMER_UPDATE_INTERVAL);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }
  }, []);

  useEffect(() => {
    if (isRecording && !isPaused) {
      manageIntervals(true);
    } else {
      manageIntervals(false);
    }
    return () => {
      manageIntervals(false);
    };
  }, [manageIntervals, isRecording, isPaused]);

  const startRecording = useCallback(
    (device: MediaDeviceInfo) => {
      const constraints = {
        audio: {
          deviceId: device?.deviceId ? { exact: device?.deviceId } : undefined,
          voiceIsolation: true,
        },
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(async (stream) => {
          recorder.current = new RecordRTC(stream, {
            type: 'audio',
            mimeType: 'audio/webm;codecs=opus',
            disableLogs: true,
          });

          const trackSettings = stream.getAudioTracks()[0].getSettings();
          MediaStream.current = stream;
          vad.current = await createVAD(stream, onSpeechEnd, setIsSpeaking, trackSettings);
          vad.current.start();
          recorder.current.startRecording();

          setIsRecording(true);
          setIsPaused(false);
          setIsInitializing(false);
          trackStartRecording(device.label);
        })
        .catch((error) => {
          console.error('Error accessing the microphone:', error);
        });
    },
    [manageIntervals],
  );

  const stopRecording = async () => {
    if (recorder.current) {
      recorder.current.stopRecording(async () => {
        MediaStream.current?.getTracks().forEach((track) => track.stop());
        vad.current?.pause();
        setIsRecording(false);
        trackStopFullRecording();
        const blob = await recorder.current.getBlob();
        const fixedBlob = await fixWebmDuration(
          new Blob([blob], { type: 'audio/webm;codecs=opus' }),
        );
        setAudioBlob(fixedBlob);
      });
    }
  };

  const resetRecorder = useCallback(() => {
    setAudioBlob(null);
    setRecordingTime(0);
    setIsRecording(false);
    recorder.current = null;
    setIsPaused(true);
    setIsInitializing(false);
    vad.current = null;
  }, []);

  const toggleRecording = (device: MediaDeviceInfo) => {
    const state = recorder.current?.getState();

    if (isRecording) {
      if (recorder.current && state === 'paused') {
        // resume recording
        recorder.current?.resumeRecording();
        vad.current?.start();
        setIsPaused(false);
        trackPauseResumeFullRecording(false);
      } else {
        // if recording, pause recording
        recorder.current?.pauseRecording();
        vad.current?.pause();
        setIsPaused(true);
        trackPauseResumeFullRecording(true);
      }
    } else {
      setIsInitializing(true);
      startRecording(device);
    }
  };

  return {
    startRecording,
    stopRecording,
    resetRecorder,
    toggleRecording,
    audioBlob,
    recordingTime,
    isRecording,
    isPaused,
    isSpeaking,
    isInitializing,
  };
};

export default useAudioRecorder;
