import { AlertColor } from '@mui/material';
import { makeAutoObservable } from 'mobx';

export class AlertStore {
  show: boolean = false;
  message = '';
  severity: AlertColor = 'error';

  constructor() {
    makeAutoObservable(this);
  }

  _showAlert(message: string) {
    this.show = true;
    this.message = message;
  }

  error(message: string) {
    this.severity = 'error';
    this._showAlert(message);
  }

  success(message: string) {
    this.severity = 'success';
    this._showAlert(message);
  }

  warning(message: string) {
    this.severity = 'warning';
    this._showAlert(message);
  }

  hide() {
    this.show = false;
  }
}
